import React from 'react';

function Wroks2() {
  return (
    <div className="section-padding pt-0">
      <div className="container">
        <div className="img">
          <img src="/assets/imgs/works/3/6.jpg" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Wroks2;
