import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// // import { ScrollSmoother } from 'gsap-trial/ScrollSmoother';

import { useGSAP } from '@gsap/react';
gsap.registerPlugin(ScrollTrigger);

function Portfolio() {
  const main = useRef();
  const smoother = useRef();
  // gsap.registerPlugin(useGSAP, ScrollTrigger);

  // useGSAP(
  //   () => {
  //     // create the smooth scroller FIRST!
  //     // smoother.current = ScrollSmoother.create({
  //     //   smooth: 2, // seconds it takes to "catch up" to native scroll position
  //     //   effects: true, // look for data-speed and data-lag attributes on elements and animate accordingly
  //     // });
  //     console.log('start');
  //     const cards = document.querySelectorAll('.cards .card-item');
  //     let stickDistance = 0;

  //     const firstCardST = ScrollTrigger.create({
  //       trigger: cards[0],
  //       start: 'center center',
  //     });

  //     const lastCardST = ScrollTrigger.create({
  //       trigger: cards[cards.length - 1],
  //       start: 'bottom bottom',
  //     });
  //     // console.log(cards, firstCardST, lastCardST);
  //     cards.forEach((card, index) => {
  //       // console.log(card);
  //       const scale = 1 - (cards.length - index) * 0.025;
  //       const scaleDown = gsap.to(card, {
  //         scale: scale,
  //         transformOrigin: '50% ' + (lastCardST.start + stickDistance),
  //       });

  //       ScrollTrigger.create({
  //         trigger: card,
  //         start: () => 'center center',
  //         end: () => lastCardST.start + stickDistance,
  //         pin: true,
  //         pinSpacing: false,
  //         ease: 'none',
  //         animation: gsap.to(card, {
  //           scale: scale,
  //           transformOrigin: '50% ' + (lastCardST.start + stickDistance),
  //         }),
  //         // onUpdate: () => scaleDown,
  //         toggleActions: 'restart none none reverse',
  //       });
  //     });
  //   },
  //   { scope: main }
  // );

  function Playing() {
    const cards = document.querySelectorAll('.cards .card-item');
    let stickDistance = 0;

    const firstCardST = ScrollTrigger.create({
      trigger: cards[0],
      start: 'center center',
    });

    const lastCardST = ScrollTrigger.create({
      trigger: cards[cards.length - 1],
      start: 'bottom bottom',
    });
    cards.forEach((card, index) => {
      const scale = 1 - (cards.length - index) * 0.025;
      const scaleDown = gsap.to(card, {
        scale: scale,
        transformOrigin: '50% ' + (lastCardST.start + stickDistance),
      });
      if (lastCardST.start && stickDistance && card && scaleDown) {
        console.log('good');

        ScrollTrigger.create({
          trigger: card,
          start: 'center center',
          end: () => lastCardST.start + stickDistance,
          pin: true,
          pinSpacing: false,
          ease: 'none',
          animation: scaleDown,
          toggleActions: 'restart none none reverse',
        });
      }
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (typeof gsap !== 'undefined') {
        clearInterval(interval);
        console.log('gsap is exist');

        const cards = document.querySelectorAll('.cards .card-item');
        let stickDistance = 0;

        const firstCardST = ScrollTrigger.create({
          trigger: cards[0],
          start: 'center center',
        });

        const lastCardST = ScrollTrigger.create({
          trigger: cards[cards.length - 1],
          start: 'bottom bottom',
        });
        cards.forEach((card, index) => {
          const scale = 1 - (cards.length - index) * 0.025;
          const scaleDown = gsap.to(card, {
            scale: scale,
            transformOrigin: '50% ' + (lastCardST.start + stickDistance),
          });

          ScrollTrigger.create({
            trigger: card,
            start: 'center center',
            end: () => lastCardST.start + stickDistance,
            pin: true,
            pinSpacing: false,
            ease: 'none',
            animation: scaleDown,
            toggleActions: 'restart none none reverse',
          });
        });
      }
    }, 100);
  }, [gsap]);

  return (
    // <React.StrictMode>
    <section className="work-card section-padding pb-0" ref={main}>
      <div className="container">
        <div className="sec-head mb-80">
          <div className="d-flex align-items-center">
            <div>
              <span className="sub-title main-color mb-5">Our Portfolio</span>
              <h3 className="fw-600 fz-50 text-u d-rotate wow">
                <span className="rotate-text">
                  Selected <span className="fw-200">Works.</span>
                </span>
              </h3>
            </div>
            <div className="ml-auto vi-more">
              <a
                href="/portfolio-grid"
                className="butn butn-sm butn-bord radius-30"
              >
                <span>View All</span>
              </a>
              <span className="icon ti-arrow-top-right"></span>
            </div>
          </div>
        </div>
        <div id="cards" className="cards">
          <div className="card-item sub-bg">
            <div className="row">
              <div className="col-lg-5">
                <div className="cont">
                  <div>
                    <div className="mb-15">
                      <a href="/portfolio-grid" className="tag">
                        Figma
                      </a>
                      <a href="/portfolio-grid" className="tag">
                        Web Design
                      </a>
                    </div>
                    <h4>Cutter mobile app</h4>
                  </div>
                  <div>
                    <p>
                      We’re a full stack firm that can help you from strategy to
                      launch, and anywhere in between.
                    </p>
                    <a href="/project-details" className="underline mt-15">
                      <span className="text main-color sub-title">
                        View Details <i className="ti-arrow-top-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="img">
                  <img src="/assets/imgs/works/1/1.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="card-item sub-bg">
            <div className="row">
              <div className="col-lg-5">
                <div className="cont">
                  <div>
                    <div className="mb-15">
                      <a href="/portfolio-grid" className="tag">
                        Figma
                      </a>
                      <a href="/portfolio-grid" className="tag">
                        Web Design
                      </a>
                    </div>
                    <h4>Cutter mobile app</h4>
                  </div>
                  <div>
                    <p>
                      We’re a full stack firm that can help you from strategy to
                      launch, and anywhere in between.
                    </p>
                    <a href="/project-details" className="underline mt-15">
                      <span className="text main-color sub-title">
                        View Details <i className="ti-arrow-top-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="img">
                  <img src="/assets/imgs/works/1/2.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="card-item sub-bg">
            <div className="row">
              <div className="col-lg-5">
                <div className="cont">
                  <div>
                    <div className="mb-15">
                      <a href="/portfolio-grid" className="tag">
                        Figma
                      </a>
                      <a href="/portfolio-grid" className="tag">
                        Web Design
                      </a>
                    </div>
                    <h4>Cutter mobile app</h4>
                  </div>
                  <div>
                    <p>
                      We’re a full stack firm that can help you from strategy to
                      launch, and anywhere in between.
                    </p>
                    <a href="/project-details" className="underline mt-15">
                      <span className="text main-color sub-title">
                        View Details <i className="ti-arrow-top-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="img">
                  <img src="/assets/imgs/works/1/3.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="card-item sub-bg">
            <div className="row">
              <div className="col-lg-5">
                <div className="cont">
                  <div>
                    <div className="mb-15">
                      <a href="/portfolio-grid" className="tag">
                        Figma
                      </a>
                      <a href="/portfolio-grid" className="tag">
                        Web Design
                      </a>
                    </div>
                    <h4>Cutter mobile app</h4>
                  </div>
                  <div>
                    <p>
                      We’re a full stack firm that can help you from strategy to
                      launch, and anywhere in between.
                    </p>
                    <a href="/project-details" className="underline mt-15">
                      <span className="text main-color sub-title">
                        View Details <i className="ti-arrow-top-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="img">
                  <img src="/assets/imgs/works/1/4.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="card-item sub-bg">
            <div className="row">
              <div className="col-lg-5">
                <div className="cont">
                  <div>
                    <div className="mb-15">
                      <a href="/portfolio-grid" className="tag">
                        Figma
                      </a>
                      <a href="/portfolio-grid" className="tag">
                        Web Design
                      </a>
                    </div>
                    <h4>Cutter mobile app</h4>
                  </div>
                  <div>
                    <p>
                      We’re a full stack firm that can help you from strategy to
                      launch, and anywhere in between.
                    </p>
                    <a href="/project-details" className="underline mt-15">
                      <span className="text main-color sub-title">
                        View Details <i className="ti-arrow-top-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="img">
                  <img src="/assets/imgs/works/1/5.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sec-bottom mt-100">
        <div className="main-bg d-flex align-items-center">
          <h6 className="fz-14 fw-400">
            More than <span className="fw-600"> 200+ companies</span>
            trusted us worldwide
          </h6>
        </div>
      </div>
    </section>
    // </React.StrictMode>
  );
}

export default Portfolio;
